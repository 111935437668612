import { useAppSelector } from './redux';
import { selected } from '@modules/organisation/selectors/organisation';

import { EComponentTypes } from '@common/definitions';

export const useEnabledComponents = <T extends string>(
  config: Record<T, keyof typeof EComponentTypes>,
) => {
  const organisation = useAppSelector(selected);
  return (Object.keys(config) as Array<T>).reduce((result, componentId) => ({
    ...result,
    [componentId]: organisation.enabled_components?.includes(config[componentId]),
  }), {} as Record<T, boolean>);
};
