import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Button } from '@common/components/button';
import UpsellModal from '@common/components/upsell-modal';

const AlertBarHome = () => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <UpsellModal
        title={t('core:upsell_home_title')}
        video={t('core:upsell_home_video')}
        intercom={{
          buttonText: t('core:upsell_home_intercom_button_text'),
          message: t('core:upsell_home_intercom_message'),
        }}
        show={showModal}
        onHide={() => setShowModal(false)}
      >
        {t('core:upsell_home_description')}
      </UpsellModal>
      <div className="AlertBar">
        <Trans i18nKey="core:upsell_home_title" components={[<b />]} />
        <Button type="white" onClick={() => setShowModal(true)}>
          {t('core:more_info')}
        </Button>
      </div>
    </>
  );
};

export default AlertBarHome;
