import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@common/components/button';
import { Organisation } from '@common/types/objects';

type AlertBarPaymentOverdueProps = {
  organisation: Organisation;
};

const AlertBarPaymentOverdue = memo(({ organisation }: AlertBarPaymentOverdueProps) => {
  const { t } = useTranslation();

  return (
    <div className="AlertBar error">
      { t('core:invoice_overdue') }
      <a href={
        `mailto:finance@oneteam.io?subject=${
          t('core:outstanding_invoice', {
            organisationName: organisation?.name || '',
          })
        }`
      }
      >
        <Button type="white">
          { t('core:contact_us') }
        </Button>
      </a>
    </div>
  );
});

export default AlertBarPaymentOverdue;
