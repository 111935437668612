import {
  FileTypes,
  EAttachmentParentTypes,
  EPrivacyLevels,
  EPermissions,
  EComponentTypes,
  EPredicateFields,
  EPredicateOperators,
  ESearchPredicateTypes,
  EPlanPackageConfig,
  EPlanLevels,
  WhiteLabelInvitationMode,
} from '@common/definitions';

import type { ObjectValues } from '@common/types/util-types';

export type RoleType = 'ADMIN' | 'EMPLOYEE';

// Deprecated
export type Location = {
  key: string,
  pathname: string,
  query: Record<string, unknown>,
  search: string,
};

export type PlaceOnMap = {
  name: string;
  geometry: {
    lat: number;
    lng: number;
  };
  address: string;
  place_id: string;
};

export type History = {
  goBack: () => void,
  goForward: () => void,
  push: (location: string) => void,
  replace: (location: string) => void,
  listen: (listener: () => unknown) => void,
};

export type MetaUser = {
  type: 'user';
  id: string;
  email: string;
  username: string;
  first_name: string;
  last_name: string;
  full_name: string;
  language_locale: string;
  translation_language_locale: string;
  profile_img: string;
  is_anonymized: boolean;
  time_zone: string;
};

export type SimpleUser = {
  id: string;
  full_name: string;
  profile_img: string | null;
  last_active: string | null;
};

export type User = SimpleUser & {
  // accepted_privacy?: boolean | void,
  // accepted_terms?: boolean | void,
  email: string,
  first_name: string,
  last_name: string,
  phone_num: string | null,
  username: string,
  phone?: {
    dial_code: string | null,
    number: string | null,
  },
  function?: string,
  created_at: string | null,
  last_login: string | null,
  date_of_birth: string | null,
  can_chat?: boolean,
  bio: string | null,
  full_name: string,
  is_anonymized?: boolean,
  language_locale?: string,
  translation_language_locale?: string,
  statistics?: {
    likes_count: number,
    messages_count: number,
  };
};

export type UserStatus = {
  emoji: any,
  expires_at: string | null,
  text: string
};

export type OrganisationScope = {
  status: UserStatus | null;
  allow_chat_invites: EPrivacyLevels,
  brand_icon: string | null,
  enabled_comment_notifications: boolean,
  enabled_flexchange_notifications: boolean,
  enabled_weekly_update: boolean,
  enabled_access_request_emails: boolean,
  external_id: string | null,
  active_until: string | null,
  id: string,
  integration_auth: boolean,
  invited_at: string,
  name: string,
  onboarding: boolean,
  onboarding_due_date: string | null,
  onboarding_invited_at: string | null,
  redeem_code: string | null,
  whitelabel_config: string | null,
  role_type: RoleType,
  roles: Role[],
  show_date_of_birth: EPrivacyLevels,
  show_last_active: EPrivacyLevels,
  show_phone_num: 0 | 1,
};

export type Role = {
  type: 'role';
  id: string;
  name: string;
  is_global: boolean;
  created_at: string;
  updated_at: string;
  created_by: string;
  last_updated_by: string;
  level: number;
  organisation_id: string;
  description: string | null;
  permissions: string[];
};

type Membership = {
  id: string;
  user_id: string;
};

export type AdminListItemMembership = Membership & {
  type: 'role_membership';
  role_id: string;
  organisation_id: string;
  network_id: string | null;
  added_by: string;
  created_at: string;
};

export type AdminListItemRole = Role & {
  memberships: AdminListItemMembership[];
};

// belongs to payload returned by /v3/organisations/{orgId}/admins
export type AdminListItem = SimpleUser & {
  type: 'user';
  id: string;
  email: string;
  username: string;
  first_name: string;
  last_name: string;
  full_name: string;
  language_locale: string;
  translation_language_locale: string;
  profile_img: string;
  is_anonymized: boolean;
  time_zone: string | null;
  roles: AdminListItemRole[];
};

type PermissionValues = ObjectValues<typeof EPermissions>[];

export type OrganisationMembership = {
  active_until: string | null;
  created_at: string;
  external_id: string | null;
  invited_at: string | null;
  notification_status: {
    next_notification_end_time: string | null;
    next_notification_start_time: string | null;
    notifications_enabled: boolean;
    schedule_paused: boolean;
    snooze_enabled: boolean;
    snooze_until: string | null;
  };
  notifications: {
    enabled_access_request_emails: boolean;
    enabled_comment_notifications: boolean;
    enabled_flexchange_notifications: boolean;
    enabled_weekly_update: boolean;
  },
  onboarding: {
    due_date: string | null;
    invited_at: string | null;
  };
  organisation_id: string;
  privacy: PrivacyLevelSettings;
  status: null;
  updated_at: string;
};

export type NetworkMembership = Membership & {
  deleted_at: string | null;
  added_by_integration: boolean;
  enabled_notifications: boolean;
  invited_at: string;
  is_welcomed: boolean;
  network_id: string;
  permissions: PermissionValues;
};

export type ChannelMembership = Membership & {
  audience_id: string;
  audience_parent_type: 'channel';
  created_at: string;
  organisation_id: string;
  type: 'audience_membership';
};

export type FunctionMembership = Membership & {
  added_by_integration: boolean;
  created_at: boolean;
  function_id: string;
};

export type TeamMembership = Membership & {
  enabled_notifications: boolean;
  team_id: string;
};

export type UIRole = Omit<Role, 'last_updated_by'> & {
  last_updated_by?: User;
};

export type NetworkScope = {
  type: 'network';
  created_at: string;
  has_integration: boolean;
  id: string;
  enabled_notifications: boolean;
  integration_auth: boolean;
  is_member: boolean;
  is_welcomed: boolean;
  name: string;
  organisation_id: string;
  role_type: RoleType;
  roles: Role[];
  invited_at: string;
};

export type LoggedUserNetworkScope = Omit<NetworkScope, 'invited_at' | 'roles'> & {
  enabled_components: ObjectValues<typeof EComponentTypes>[];
  permissions: ObjectValues<typeof EPermissions>[];
  has_integration: boolean;
};

export type ChannelScope = {
  enabled_notifications: boolean,
  id: string,
  name: string,
  organisation_id: string,
  settings: {
    can_all_post: boolean,
    can_comment: boolean,
    can_post: boolean,
  },
  theme: {
    logo_url: string | null,
    header_image_url: string | null,
  },
};

export type FunctionScope = {
  id: string;
  oragnisation_id: string;
  name: string;
  is_member: boolean;
  added_by_integration: boolean;
  created_at: string;
};

export type LoggedUserFunctionScope = Omit<FunctionScope, 'created_at'>;

export type Scopes = {
  status: boolean,
  organisation: OrganisationScope,
  networks: NetworkScope[],
  functions: FunctionScope[],
  teams: Team[],
  channels: ChannelScope[],
};

export type PrivacyLevelSettings = {
  allow_chat_invites: EPrivacyLevels;
  show_date_of_birth: EPrivacyLevels;
  show_last_active: EPrivacyLevels;
  show_phone_num: EPrivacyLevels;
};

export type ExtendedUser = User & {
  scopes: Scopes,
  biography: string | null,
  accepted_privacy?: boolean,
  accepted_terms?: boolean,
  phone: {
    number: string;
    dial_code: string;
  };
};

export enum ELanguages {
  DUTCH = 'nl',
  ENGLISH = 'en',
  GERMAN = 'de',
  FRENCH = 'fr',
}

export type LanguageLocale = {
  locale: ELanguages;
  name: string;
  name_eng: string;
  icon: string;
  created_at: string;
};

export type LoggedUser = ExtendedUser & {
  role_type: RoleType;
  accepted_privacy: boolean;
  accepted_terms: boolean;
  time_zone: string;
  language: LanguageLocale;
  translation_language: LanguageLocale;
  settings: OrganisationMembership;
};

export type Network = {
  enabled_components?: string[],
  external_id?: string | null,
  has_integration?: boolean,
  id: string,
  name: string,
  organisation_id?: string,
  member_count: number,
  created_at: string,
};

export type NetworkLink = {
  id: string,
  organisation_id: string,
  name: string,
  integration_auth: boolean,
  role_type: RoleType,
};

type PlanPackageConfig = {
  [key in EPlanPackageConfig]: number | boolean;
};

export type PlanPackage = PlanPackageConfig & {
  id: string;
  level: EPlanLevels;
};

export type Organisation = {
  available_components: Array<string>;
  id: string;
  enabled_components: Array<string>;
  external_id: string | null;
  name: string;
  brand_icon: string | null;
  network_ids: Array<string>;
  networks: Array<Network>;
  role_type?: RoleType;
  is_demo: boolean;
  onboarding: boolean;
  onboarding_invited_at: string | null;
  onboarding_due_date: string | null;
  enabled_comment_notifications: boolean;
  enabled_flexchange_notifications: boolean;
  enabled_weekly_update: boolean;
  redeem_code: string | null;
  access_request_links_enabled: boolean;
  alerts: {
    activities: number;
    chat: number;
  }
  theme?: {
    header_background_url: string | null;
    main_color: string | null;
    button_color: string | null;
    link_color: string | null;
    splash_icon_url: string | null;
    login_image_url: string | null;
    favicon: string | null;
  };
  integration: {
    display_name: string;
    external_schedule: boolean;
    flexchange_type: 'default' | 'integrated';
    integration_type: 'pmt' | 'manusplus';
    should_authenticate: boolean;
    process_exchange_approvals: boolean;
    last_synced_at: string | null;
  } | null;
  whitelabel_config: {
    app_name: string;
    invitation_mode: WhiteLabelInvitationMode;
    urls?: {
      web?: string | null;
    };
  } | null;
  trial: {
    expires_at: string;
    setup_completed: boolean;
  } | null;
  settings: {
    disable_weekly_update: boolean;
    onboarding_due_date_offset: number | null;
    require_user_introduction: boolean;
    default_user_privacy: {
      allow_chat_invites: EPrivacyLevels;
      show_date_of_birth: EPrivacyLevels;
      show_phone_num: EPrivacyLevels;
      show_last_active: EPrivacyLevels;
    };
  };
  language: {
    locale: string;
    name: string;
    name_eng: string;
    icon: string;
  };
  permissions: string[];
  plan_package: string;
  plan_package_list: PlanPackage[];
};

export type IntegratedOrganisation = {
  integration: {
    display_name: string,
    external_schedule: boolean,
    flexchange_type: 'default' | 'integrated',
    integration_type: 'pmt' | 'manusplus',
    should_authenticate: boolean,
  },
} & Organisation;

export type OrganisationLink = {
  brand_icon: string,
  external_id: string | null,
  id: string,
  integration_auth: boolean,
  name: string,
  onboarding: boolean,
  role_type: RoleType,
};

export type OrganisationFunction = {
  id: string,
  name: string,
  external_id: string | null,
  organisation_id: string,
  users_count: number,
};

export type FunctionLink = {
  id: string,
  organisation_id: string,
  name: string,
};

export type Team = {
  id: string,
  network_id: string,
  name: string,
  is_member: boolean,
  is_synced: boolean,
  member_count: number,
  users_count: number,
  external_id: string | null,
};

export type TeamLink = {
  id: string,
  network_id: string,
  name: string,
};

export type Notification = {
  activity_type: string,
  created_at: string,
  id: string,
  meta: Record<string, unknown>,
  network_id: string | null,
  organisation_id: string,
  seen: boolean,
  source_id: string | null,
  source_type: string | null,
  target_id: string,
  target_type: 'user' | 'network' | 'organisation',
  text: string | null,
  user: SimpleUser | null,
  user_id: string,
};

export type ActivityType =
  | 'comment_created'
  | 'exchange_created'
  | 'exchange_accepted'
  | 'exchange_approved'
  | 'exchange_comment_created'
  | 'added_to_groupchat'
  | 'highfive_added'
  | 'highfive_added_for_mention'
  | 'welcome_message_created'
  | 'birthdays_today'
  | 'onboarding_reminder'
  | 'new_onboarding_category_available'
  | 'new_course'
  | 'course_almost_due'
  | 'event_message_created'
  | 'event_users_invited'
  | 'event_availability_reminder'
  | 'form_submission_created'
  | 'form_submission_status_updated'
  | 'survey_published'
  | 'survey_reminder';

type SourceType =
  | 'message'
  | 'exchange'
  | 'conversation'
  | 'onboarding'
  | 'schedule'
  | 'course';

type TargetType = 'user' | 'network' | 'organisation';

export type Activity = {
  activity_type: ActivityType;
  created_at: string;
  group_count: number;
  id: string;
  meta: Record<string, any>;
  network_id: string | null;
  organisation_id: string;
  seen: boolean;
  source_id: string;
  source_type: SourceType;
  target_id: string;
  target_type: TargetType;
  text: string;
  user_id: string;
  user: SimpleUser | null;
};

export type DataManager = {
  get: () => {
    items: Array<any>,
    pagination: {
      limit: number,
      offset: number,
      total_count: number,
    },
  }
};

export type Error = {
  detail: string,
  error_code: string,
  error_info?: {
    app_name: string,
    urls?: {
      web?: string,
    },
  },
  status_code: number,
  type: string,
};

export type Language = {
  locale: string,
  name: string,
  name_eng: string,
  icon: string,
  created_at: string,
};

export type Attachment = {
  type: 'attachment' | 'other_attachment';
  id: string;
  user_id: string | null;
  message_id: string | null;
  message_order: number | null;
  organisation_id: string | null;
  parent_id: string | null;
  parent_type: EAttachmentParentTypes | null;
  is_remote_file: boolean;
  path: string | null;
  raw_path: string;
  thumb_path: string | null;
  raw_thumb_path: string | null;
  download_path: string | null;
  file_type: FileTypes | null;
  file_extension: string | null;
  file_name: string | null;
  file_size: number | null;
  is_document: boolean;
  created_at: string;
  completed: boolean;
  job_id: string | null;
  processing: boolean;
  version_number: number;
  meta_data: {
    width: number;
    height: number;
  } | null;
};

export type FileInProgress = {
  file_name?: string;
  file_type?: string;
  preview?: string | ArrayBuffer | null;
  processing?: boolean;
  progress?: (callbackFn?: (value: number) => void) => any;
  promise?: Promise<any>;
  cancelPromise?: (isCancelled?: boolean) => void;
};

type LinkPreviewImage = {
  url: string;
  width: number;
  height: number;
};

export type LinkPreview = {
  url: string;
  title: string;
  name: string | null;
  description: string | null;
  image: LinkPreviewImage | null;
  icon: LinkPreviewImage | null;
};

export type MinimalEmojiReaction = {
  short_name: string;
  character: string;
};

export type EmojiReaction = MinimalEmojiReaction & {
  count: number;
  is_selected_by_user: boolean;
};

export type StoredEmojiReaction = EmojiReaction & {
  userIds?: string[];
  usersNextCursor?: string | null;
};

export type MessageEmojiReaction = Omit<StoredEmojiReaction, 'userIds'> & {
  users?: SimpleUser[];
};

export type AllowedEmoji = MinimalEmojiReaction & {
  unicode_full_name: string;
};

type BasePredicate = {
  id?: string;
};

export type NetworkPredicate = BasePredicate & {
  attribute: EPredicateFields.NETWORK;
} & ({
  comparison: EPredicateOperators.IN;
  value: string[],
} | {
  comparison: EPredicateOperators.EQ;
  value: string,
});

export type FunctionPredicate = BasePredicate & {
  attribute: EPredicateFields.FUNCTION;
} & ({
  comparison: EPredicateOperators.IN;
  value: string[],
} | {
  comparison: EPredicateOperators.EQ;
  value: string,
});

export type UserPredicate = BasePredicate & {
  attribute: EPredicateFields.USER;
} & ({
  comparison: EPredicateOperators.IN;
  value: string[],
} | {
  comparison: EPredicateOperators.EQ;
  value: string,
});

export type LanguagePredicate = BasePredicate & {
  attribute: EPredicateFields.LANGUAGE;
} & ({
  comparison: EPredicateOperators.IN;
  value: string[],
} | {
  comparison: EPredicateOperators.EQ;
  value: string,
});

export type DaysInServicePredicate = BasePredicate & {
  attribute: EPredicateFields.DAYS_IN_SERVICE;
} & ({
  comparison: EPredicateOperators.GT | EPredicateOperators.GTE | EPredicateOperators.LT | EPredicateOperators.LTE;
  value: number,
} | {
  comparison: EPredicateOperators.BETWEEN;
  value: number[],
});

export type DaysBeforeOutOfServicePredicate = BasePredicate & {
  attribute: EPredicateFields.DAYS_BEFORE_OUT_OF_SERVICE;
} & ({
  comparison: EPredicateOperators.GT | EPredicateOperators.GTE | EPredicateOperators.LT | EPredicateOperators.LTE;
  value: number,
} | {
  comparison: EPredicateOperators.BETWEEN;
  value: number[],
});

export type DatePredicate = BasePredicate & {
  attribute: EPredicateFields.DATE;
} & ({
  comparison: EPredicateOperators.GT | EPredicateOperators.GTE | EPredicateOperators.LT | EPredicateOperators.LTE;
  value: Date | null,
} | {
  comparison: EPredicateOperators.BETWEEN;
  value: (Date | null)[],
});

export type AnswerPredicate = BasePredicate & {
  attribute: 'answers.value',
  comparison: EPredicateOperators,
  value: unknown,
};

export type Predicate =
  | NetworkPredicate
  | FunctionPredicate
  | UserPredicate
  | LanguagePredicate
  | DaysInServicePredicate
  | DaysBeforeOutOfServicePredicate
  | DatePredicate
  | AnswerPredicate;

export type Filters = {
  networks: Network[],
  functions: OrganisationFunction[],
  users: SimpleUser[],
};

export type Audience = {
  id: string;
  created_at: string;
  deleted_at: string | null;
  updated_at: string | null;
  is_active: boolean;
  name: string;
  organisation_id: string;
  parent_type: string;
  type: string;
  predicate_type: `${ESearchPredicateTypes}`;
  predicates: Predicate[];
};

export type URL = string;
